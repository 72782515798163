import React, { memo } from "react"
import { FormControl, FormLabel } from "@chakra-ui/react"
import { CustomSelect } from "../../Input/CustomSelect"

type Props = {
  field: {
    _key: string
    _type: string
    label: string
    name: string
    placeholder?: string
    type: "text" | "email" | "tel"
    required?: boolean
    options?: string[]
  }
}

export const FormSelect: React.FC<Props> = memo(({ field }) => (
  <FormControl key={field._key} id={field?.name} isRequired={field?.required}>
    {field?.label && <FormLabel>{field?.label}</FormLabel>}
    <CustomSelect name={field?.name} placeholder={field?.placeholder}>
      {field?.options?.map((option: string) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </CustomSelect>
  </FormControl>
))
